import React, { useEffect } from "react"

const RedirectToPage = ({ url }) => {
  useEffect(() => {
    window.location.replace(url)
  }, [])
  return <></>
}

export default RedirectToPage
